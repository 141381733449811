import * as React from 'react'
import {PageProps} from 'gatsby'
import {useQueryParam, StringParam} from 'use-query-params'
import {Layout, SiteConfigData} from '../../components/Layout'
import {ContentfulPage, ContentfulPageData} from '../../components/ContentfulPage'
import {useContentful, transformEntry, PreviewPageContext} from '../../utils/preview'

export const PagePreview: React.FC<PageProps<unknown, PreviewPageContext>> = ({pageContext}) => {
  const [pageId] = useQueryParam('id', StringParam)
  const {useEntry} = useContentful(pageContext.contentfulWebsiteSpaceConfig)
  const [layout, layoutIsLoading] = useEntry(pageContext.contentfulSiteConfigEntryId)
  const [entry, pageIsLoading] = useEntry(pageId)

  if (pageIsLoading || layoutIsLoading) {
    return (
      <div className="container">
        <h1 className="is-size-1 has-text-centered">Loading...</h1>
      </div>
    )
  }

  const siteConfig = transformEntry<SiteConfigData>(layout)
  const pageData = transformEntry<ContentfulPageData>(entry)

  return (
    <Layout isPreview data={siteConfig}>
      <ContentfulPage data={pageData} />
    </Layout>
  )
}

export default PagePreview
